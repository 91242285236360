body {
    font-family: 'Open Sans', sans-serif !important;
}
.column-info{
    background-color: #F5F5F5;
    padding: 20px;
    border-radius: 8px;
    margin-left: 10px;
    margin-right: 10px;
}

#KIPs{
    margin-top: 45px;
    margin-bottom: 45px;
}

.image-icon1 {
    vertical-align: middle;
}

.image-icon1 img{
    width: 100%;
    padding-top: 40px;
}

.image-icon2 {
    vertical-align: middle;
}

.image-icon2 img{
    width: 80%;
    padding-top: 30px;
    margin-left: 10px;
}

.image-icon3 {
    vertical-align: middle;
}

.image-icon3 img{
    width: 80%;
    padding-top: 30px;
    margin-left: 10px;
}

.image-icon4 {
    vertical-align: middle;
}

.image-icon4 img{
    width: 80%;
    padding-top: 30px;
    margin-left: 10px;
}

.image-icon5 {
    vertical-align: middle;
}

.image-icon5 img{
    width: 80%;
    padding-top: 30px;
    margin-left: 10px;
}


.title1{
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
}

.imgBanner1 img{
    width: 100%;
    margin: 0;
}

.imgBanner2{
    margin-top: 16px;
}

.imgBanner2 img{
    width: 100%;
    margin: 0;
}

.image_app{
    margin: 90px 0 20px 0;
}

.mobile_app_image{
    text-align: right;
}

.mobile_app_image img{
    width: 80%;
}

.app_details h1{
    font-weight: bold;
}

.googlePlayStore{
    margin-top: 40px;
    margin-bottom: 5px !important;
}

.googlePlayStoreImg {
    margin-left: 0px;
}

.googlePlayStoreImg img{
    width: 30%;
}

.emailApp{
    margin-top: 70px;
}

.extraSection{
    margin-top: 90px;
    background-color: #f9f9f9 !important;
    text-align: center;
    padding: 40px 0 24px 0;
}

.extraSection p {
    padding-bottom: 12px;
    margin-bottom: 0px !important;
}

.extraSection h4 {
    padding-bottom: 16px;
}

.extraSectionBox{
    border-right: 1px solid #EAEAEA;
}

.banner_Section_4{
    margin: 90px 0;
}

.banner_Section_4 img{
    width: 100%;
    margin-bottom: 16px;
}

.Aboutus {
    text-align: center;
}

.Aboutus h4{
    margin-bottom: 32px;
}

.HowOffer ul{
    font-size: 16px;
}

.HowOffer ul li{
    list-style-type: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.HowOffer p {
    margin-top: 0;
    margin-bottom: 0px !important;
    margin-left: 16px;
}

.HowOrder{
    margin: 90px 0;
    
}

.HowOrder h4{
    margin-bottom: 24px;
    text-align: center;
}

.HowOrder ul li {
    margin-bottom: 16px;
}

.quickline ul {
    padding-left: 0px !important;
}

.quickline ul li {
    list-style: none;
    margin-bottom: 8px;
    line-height: 16px;
}

.quickline ul li img {
    margin-right: 8px;
}

.quickline p {
    font-weight: 400;
    font-size: 14px;
    margin-top: 16px;
}

.quickline p span {
    font-weight: bold;
    color: #000080;
}

.aboutus{
    background-color: #000000;
    color: #fff;
    padding-top: 16px;
    padding-bottom: 16px;
}

.breadcrum-1 a {
    color: rgba(255, 255, 255, 1) !important;
}


.breadcrumb-item.active{
    color: rgba(255, 255, 255, 0.5) !important;
}

.breadcrum-1 * {
    color: #fff !important;
}

.aboutus-middle{
    margin-top: 45px;
    margin-bottom: 45px;
}

.whoWeAre{
    text-align: center;
}
.whoWeAre h3 {
    text-decoration: underline;
}

.aboutus-middle img {
    width: 100%;
    margin-top: 45px;
}

.ContactUs{
    margin: 45px 0;
}

.contact-wrap {
    background-color: #f2f2f2;
    padding: 80px 50px;
    box-shadow: 0px 16px 28px 0px rgba(0, 0, 0, 0.15);
    margin-top: 110px;
    border-radius: 5px;
}

.contact-section h3 {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 18px;
}

.contact-section p {
    font-size: 17px;
    margin-bottom: 15px;
}

ul.contact-info {
    display: block;
    list-style: none;
    padding-left: 0px !important;
}

.contact-info ul li {
    font-family: "Jost",sans-serif;
    font-weight: 500;
    font-size: 17px;
    line-height: 40px;
    display: flex;
    align-items: center;
}

.contact-info ul li i {
    margin-right: 8px;
}
